import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ErrorPage } from './pages/Error';
import { Logout } from './pages/Logout';
import { ForgoutPassword } from './pages/ForgoutPassword';
import Login from './pages/Login';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route path="/forgout-password">
          <ForgoutPassword />
        </Route>

        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
