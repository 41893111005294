let kk = {
  title: "ГБИМ",
  title_full: "Aqparattyq modelderdіń memlekettіk bankі",
  form_header: "Júıege kirý",
  loginPass: "Logın jáne paról",
  eds: "ESQ",
  login: "Logın",
  pass: "Paról",
  email: "Elektrondyq poshta",
  passwordPlaceholder: "Keminde 8 tańba",
  signIn: "Kirý",
  noAccount: "Eseptik jazba joq pa?",
  registration: "Tirkeý",
  chooseCertificateMessage: "Júıege kirýdi júzege asyrǵyńyz keletin QR UKO bergen sertıfıkatty (ESQ) tańdańyz:",
  buttonChooseCert: "Sertıfıkatty tańdańyz",
  ecp_error: "Sizdiń sertıfıkatyńyz QR UKO derekteri boıynsha jaramsyz bolyp tabylady, jaramdy sertıfıkatty paıdalanýyńyzdy ótinemiz",
  warning: "Júıede tirkeý jáne avtorızasıalaý kezinde úshinshi tulǵanyń eseptik derekterin zańsyz paıdalaný QR zańnamasyna sáıkes jaýapkershilikke ákep soǵady.",
  forgoutPassword: 'Qupıa sózdi umyttyńyz ba?',

  emailForConfirmCode: 'Rastaý kody úshin Email',
  getConfirmCode: 'Rastaý kodyn alyńyz',
  resendConfirmCode: 'Kodty qaıta jiberý',
  enterCodeFromMail: 'Hattyń kodyn engizińiz',
  newPassword: 'Jańa paról',
  confirmPassword: 'Qupıa sózdi qaıtalańyz',
  save: 'Saqtaý',
  left: `Kaldy `,
  second: ` Second`,
  sentToEmail: "Rastaý kody sizdiń poshtańyzǵa jiberildi",
  required: "Bul jol toltyrylýy tıis",
  passwordDoNotMatch: "Parólder sáıkes kelmeıdi",
  strongPassword: "Paról keminde \"eki\" arnaıy tańbany qamtýy tıis(@, #, $, &, *, % jáne t. b.), kem degende bir san jáne latyn áripterinen turady",

  logoutTile: "Júıeden shyǵý sátti.",
  logoutWarning: "Siz júıeden sátti shyqtyńyz. Qaýipsizdiktiń senimdi deńgeıi úshin braýzerdi jabyńyz.",
  signInTitle: "Juiege kiry"
};
export default kk;
