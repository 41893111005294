let ru = {
  title: "ГБИМ",
  title_full: "Государственный банк информационных моделей",
  form_header: "Вход в систему",
  loginPass: "Логин и пароль",
  eds: "ЭЦП",
  login: "Логин",
  pass: "Пароль",
  email: "Email",
  passwordPlaceholder: "Не менее 8 символов",
  signIn: "Войти",
  noAccount: "Нет учетной записи?",
  registration: "Регистрация",
  chooseCertificateMessage: "Выберите сертификат (ЭЦП), выданный НУЦ РК, с помощью которого вы захотите осуществить вход в систему:",
  buttonChooseCert: "Выберите сертификат",
  ecp_error: "Ваш сертификат является недействительным по данным НУЦ РК, пожалуйста, воспользуйтесь действительным сертификатом",
  warning: "Неправомерное использование учетных данных третьего лица при регистрации и авторизации в системе влечет ответственность в соответствии с законодательством РК.",
  forgoutPassword: 'Забыли пароль?',

  emailForConfirmCode: 'Email для код подтверждения',
  getConfirmCode: 'Получить код подтверждения',
  resendConfirmCode: 'Отправить код повторно',
  enterCodeFromMail: 'Введите код из письма',
  newPassword: 'Новый пароль',
  confirmPassword: 'Повторите пароль',
  save: 'Сохранить',
  left: `Осталось `,
  second: ` секунд`,
  sentToEmail: "На вашу почту был отправлен код подтверждения",
  required: "Данное поле должно быть заполнено",
  passwordDoNotMatch: "Не совпадают пароли",
  strongPassword: "Пароль должен включать не менее \"двух\" спец символов (@, #, $, &, *, % и т.п.), минимум одну цифру и состоять из латинских букв",

  logoutTile: "Выход из системы успешен",
  logoutWarning: "Вы успешно вышли из системы. В целях надежного уровня безопасности, закройте браузер.",
  signInTitle: "Вход в систему"
};
export default ru;
