import {GET_TRANSLATIONS_EN, GET_TRANSLATIONS_KK, GET_TRANSLATIONS_RU, SET_TRANSLATION_EN, SET_TRANSLATION_KK, SET_TRANSLATION_RU} from "../actions/actionTypes";
import ru from '../../translations/ru'
import kk from '../../translations/kk'
import en from '../../translations/en'


const initialState = {
    kk: kk,
    ru: ru,
    en: en,
    loading: false
}

export default function translateReduce(state = initialState, action) {
    switch (action.type) {
        case GET_TRANSLATIONS_KK:
            return {
                state: state.kk,
                loading: state.loading
            }
        case GET_TRANSLATIONS_RU:
            return {
                state: state.ru,
                loading: state.loading
            }
        case GET_TRANSLATIONS_EN:
            return {
                state: state.en,
                loading: state.loading
            }
        case SET_TRANSLATION_KK:
            return {
                ...state,
                kk: {
                    ...state.kk,
                    ...action.payload
                },
                loading: true
            }
        case SET_TRANSLATION_RU:
            return {
                ...state,
                ru: {
                    ...state.ru,
                    ...action.payload
                },
                loading: true
            }
        case SET_TRANSLATION_EN:
            return {
                ...state,
                en: {
                    ...state.en,
                    ...action.payload
                },
                loading: true
            }
        default:
            return state;
    }
}