let en = {
  title: "GBIM",
  title_full: "STATE BANK OF INFORMATION MODELS",
  form_header: "Login to the system",
  loginPass: "Login and password",
  eds: "ECP",
  login: "Login",
  pass: "Password",
  email: "Email",
  passwordPlaceholder: "At least 8 characters",
  signIn: "Sign in",
  noAccount: "No account?",
  registration: "Registration",
  chooseCertificateMessage: "Select the certificate (ECP) issued by the NCA RK, with which you want to log in to the system:",
  buttonChooseCert: "Select certificate",
  ecp_error: "Your certificate is invalid according to NCA RK, please use a valid certificate",
  warning: "Illegal use of third party credentials during registration and authorization in the system entails liability in accordance with the legislation of the Republic of Kazakhstan",
  forgoutPassword: 'Forgot your password?',

  emailForConfirmCode: 'Email for confirmation code',
  getConfirmCode: 'Get a confirmation code',
  resendConfirmCode: 'Send the code again',
  enterCodeFromMail: 'Enter the code from the email',
  newPassword: 'New password',
  confirmPassword: 'Repeat the password',
  save: 'Save',
  left: `Left  `,
  second: ` Second`,
  sentToEmail: "A confirmation code has been sent to your email",
  required: "This field must be filled in",
  passwordDoNotMatch: "Passwords don't match",
  strongPassword: "The password must include at least \"two\" special characters (@, #, $, &, *, % etc.), at least one digit and consist of Latin letters",

  logoutTile: "The logout is successful.",
  logoutWarning: "You have successfully logged out. For a reliable level of security, close your browser.",
  signInTitle: "Login to the system"
};
export default en;