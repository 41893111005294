import { SET_CONFIG, SET_TRANSLATION_EN, SET_TRANSLATION_KK, SET_TRANSLATION_RU } from "./actionTypes";
import { Http } from "../../Http";

export function getTranslations(config, themeInfo) {
  return async (dispatch) => {
    const responseKk = await Http.get(`${config.client_url}/translations/kk.json`);
    const responseRu = await Http.get(`${config.client_url}/translations/ru.json`);
    const responseEn = await Http.get(`${config.client_url}/translations/en.json`);

    dispatch(setTranslationsKk(responseKk));
    dispatch(setTranslationsRu(responseRu));
    dispatch(setTranslationsEn(responseEn));
  };
}

export function loadConfig() {
  return async (dispatch) => {
    const response = await Http.get(`/config.json`);
    dispatch(setConfig(response));
  };
}

export function setTranslationsKk(value) {
  return {
    type: SET_TRANSLATION_KK,
    payload: value
  };
}

export function setTranslationsRu(value) {
  return {
    type: SET_TRANSLATION_RU,
    payload: value
  };
}

export function setTranslationsEn(value) {
  return {
    type: SET_TRANSLATION_EN,
    payload: value
  };
}

export function setConfig(value) {
  return {
    type: SET_CONFIG,
    payload: value
  };
}