import React, { useEffect, useState } from "react";
import { Http } from "../Http";
import _ from "lodash";
import { getTranslations, loadConfig } from "../redux/actions/actions";
import { useDispatch, useSelector } from 'react-redux';

export function ForgoutPassword(props) {
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translate);
  const config = useSelector((state) => state.app.config);

  const [htmlTemplate, setHtmlTemplate] = useState('');
  const [forgoutPasswordHtml, setForgoutPasswordHtml] = useState(null);
  const [theme, setTheme] = useState(null);
  const [currentLanguage] = useState(localStorage.getItem('lang') ?? 'ru');
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const errorMessage = document?.getElementById('errorMessage');
  const emailError = document?.getElementById('emailError');
  const codeError = document?.getElementById('codeError');
  const newPasswordError = document?.getElementById('newPasswordError');
  const confirmPasswordError = document?.getElementById('confirmPasswordError');

  useEffect(() => {
    (async () => {
      if (!config) {
        dispatch(loadConfig());
      }

      if (config && !htmlTemplate) {
        const themeInfo = await Http.get(`${config.client_url}/theme.json`);
        const text = await Http.getText(`${config.client_url}${themeInfo.forgoutPassword}`);

        setHtmlTemplate(text);
        setTheme(themeInfo);
        dispatch(getTranslations(config, themeInfo));
      }

      if (htmlTemplate) {
        const html = _.template(htmlTemplate);
        setForgoutPasswordHtml(html({
          errorMessage: errorMessage,
          t: translations[currentLanguage],
          data: {
            currentLanguage: currentLanguage
          }
        }));
      }
    })();
  });

  window.onChangeHandler = (event) => {
    event.value.toString().replace(/\s/g, "");
    event.value.toString().trim();

    const name = event.name;
    const value = event.value;

    switch (name) {
      case 'email':
        setEmail(value);

        emailError.innerHTML = !value ? translations[currentLanguage].required : '';
        break;
      case 'code':
        setCode(value);

        codeError.innerHTML = !value ? translations[currentLanguage].required : '';
        break;
      case 'newPassword':
        setNewPassword(value);
        newPasswordError.innerHTML = !value
          ? translations[currentLanguage].required
          : '';


        confirmPasswordError.innerHTML = confirmPassword !== value
          ? translations[currentLanguage].passwordDoNotMatch
          : '';

        const strongPasswordRegex = new RegExp([
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/
          , /(?=.*[!@#$%^&*№'"()_+\-=\[\]{};:\\|,.<>\/?].*[!@#$%^&*№'"()_+\-=\[\]{};:\\|,.<>\/?])/
          , /[0-9a-zA-Z!@#$%^&*№'"()_+\-=\[\]{};:\\|,.<>\/?]{8,}$/
        ].map(r => r.source).join(''));

        newPasswordError.innerHTML = !strongPasswordRegex.test(value)
          ? translations[currentLanguage].strongPassword
          : '';
        break;
      case 'confirmPassword':
        setConfirmPassword(value);

        confirmPasswordError.innerHTML = !value
          ? translations[currentLanguage].required
          : value !== newPassword
            ? translations[currentLanguage].passwordDoNotMatch
            : '';
        break;
      default: return;
    }
  };

  window.getConfirmationCode = async (event) => {
    event.preventDefault();
    const firstFormElement = document.getElementById('first-form');
    const submitBtn = document.getElementById('submit');
    let code;

    if (email) {
      code = await Http.post(`${config.server_url}/api/AuthByLogin/sendVerificationCode/${email}`);
    }

    if (!code || !code.isOk) {
      emailError.innerHTML = !email ? translations[currentLanguage].required : '';
      errorMessage.innerHTML = code?.errorMessage ? code.errorMessage : '';
      submitBtn.disabled = true;
      return;
    }

    if (firstFormElement && code) {
      const secondFormElement = document.getElementById('second-form');
      submitBtn.disabled = false;

      firstFormElement.classList.add('display-none');
      secondFormElement.classList.remove('display-none');

      notify(translations[currentLanguage].sentToEmail);
      errorMessage.innerHTML = '';
    }

    loadTimer();
  };

  function notify(text) {
    const content = document.getElementById('content');
    const notify = document.createElement('div');

    if (content && notify) {
      notify.classList = 'notify-container';
      notify.innerHTML = text;

      content.appendChild(notify);
    }

    setTimeout(() => {
      content.removeChild(notify);
    }, 6000);
  }

  function loadTimer() {
    let seconds = 60;
    const timerElement = document.getElementById('timer');
    const resendCodeBtn = document.getElementById('resendCodeBtn');
    const submitBtn = document.getElementById('submit');
    resendCodeBtn?.classList.add('display-none');

    const interval = setInterval(() => {
      timerElement.innerHTML = translations[currentLanguage].left + (--seconds) + translations[currentLanguage].second;

      if (seconds <= 0) {
        timerElement.innerHTML = '';
        resendCodeBtn.classList.remove('display-none');
        submitBtn.disabled = true;

        setCode(null);
        clearInterval(interval);
      };

    }, 1000);
  }

  window.visibilityPassword = (input, img) => {
    input.type = input.type === 'password' ? 'text' : 'password';
    img.src = input.type === 'password'
      ? '../images/visibility.svg'
      : '../images/visibility_off.svg';
  };

  window.save = (event) => {
    event.preventDefault();

    if (email && code && newPassword && confirmPassword) {
      Http.post(`${config.server_url}/api/AuthByLogin/resetPassword`, {
        userName: email,
        verificationCode: code,
        newPassword: newPassword,
        confirmPassword: confirmPassword
      }).then(response => {
        if (response?.errorMessage) {
          errorMessage.innerHTML = response.errorMessage;
        }

        if (response.isOk) {
          window.location = localStorage.getItem('returnUrl');
        }
      });
    }
  };

  return (
    <div>
      {(config && forgoutPasswordHtml) &&
        <span>
          <link rel="stylesheet" href={`${config.client_url}${theme.customCssFile}`}></link>
          <div dangerouslySetInnerHTML={{ __html: forgoutPasswordHtml }}></div>
        </span>
      }
    </div>
  );

}