import { useEffect, useState } from "react";
import { Http } from "../Http";
import _ from "lodash";
import { getTranslations, loadConfig } from "../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';

export function Logout(props) {
  const [iframeUrl, setIframeUrl] = useState(null);
  const [template, setTemplate] = useState(undefined);
  const [preparedTemplate, setPreparedTemplate] = useState('');
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [theme, setTheme] = useState(null);
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translate);
  const config = useSelector((state) => state.app.config);

  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('lang') ?? 'ru');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!config) {
      dispatch(loadConfig());
      setCurrentLanguage(Cookies.get('culture')?.toLowerCase() ?? currentLanguage);
    }

    if (!translations.loading && config && theme) {
      dispatch(getTranslations(config, theme));
    }

    if (!template && config) {
      const urlParams = new URLSearchParams(window.location.search);
      const logoutId = urlParams.get('logoutId');
      const themeInfo = await Http.get(`${config.client_url}/theme.json`);
      const text = await Http.getText(`${config.client_url}${themeInfo.logoutPage}`);

      setTemplate(text);
      setTheme(themeInfo);

      Http.logout(`${config.server_url}/api/Common/logout?logoutId=${logoutId}`).then(data => {
        if (data.signOutIFrameUrl) {
          setIframeUrl(data.signOutIFrameUrl);
        }
        if (data.postLogoutRedirectUri) {
          setRedirectUrl(data.postLogoutRedirectUri);
          setTimeout(() => {
            window.location = data.postLogoutRedirectUri;
          }, 2000);
        }
      });
    }

    const html = _.template(template);
    setPreparedTemplate(html({
      t: translations[currentLanguage],
      data: {
        'currentLanguage': currentLanguage,
        'iframeUrl': iframeUrl,
        'redirectUrl': redirectUrl,
        registrationUrl: (config?.registration_url),
        portalUrl: (config?.portal_url),
        forgoutPasswordUrl: (config?.forgout_password_url)
      }
    }));

    if (currentLanguage) {
      const langDocument = document.getElementById(currentLanguage);
      langDocument?.classList.add('langActive');
    }
  });

  window.changeLanguage = (event, lang) => {
    setCurrentLanguage(lang);

    setTimeout(() => {
      const langDocument = document.getElementById(lang);
      langDocument.classList.add('langActive');

      localStorage.setItem('lang', lang);
    }, 0);
  };

  return (
    <div>
      {(config && preparedTemplate) &&
        <span>
          <link rel="stylesheet" href={`${config.client_url}${theme.customCssFile}`}></link>
          <div dangerouslySetInnerHTML={{ __html: preparedTemplate }}></div>
        </span>
      }
    </div>
  );
}