import { useEffect, useState } from 'react';
import { NcaLayer } from '../NCALayer';
import { Http } from '../Http';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { getTranslations, loadConfig } from "../redux/actions/actions";
import Cookies from 'js-cookie';

const ncaInstance = new NcaLayer();

export default function Login(props) {
  const [signedXml, setSignedXml] = useState('');
  const [canSignIn, setCanSignIn] = useState(false);
  const [password, setPassword] = useState('random');
  const [signWithPassword, setSignWithPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [showEmail, setShowEmail] = useState(false);
  const [template, setTemplate] = useState(undefined);
  const [preparedTemplate, setPreparedTemplate] = useState('');
  const [theme, setTheme] = useState(undefined);
  const [certStore, setCertStore] = useState('PKCS12');
  const [errorMessage, setErrorMessage] = useState("");
  const [customErrorMessage, setCustomErrorMessage] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('lang') ?? 'ru');
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translate);
  const config = useSelector((state) => state.app.config);
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!config) {
      dispatch(loadConfig());
      setCurrentLanguage(Cookies.get('culture')?.toLowerCase() ?? currentLanguage);
    }

    if (!translations.loading && config && theme) {
      dispatch(getTranslations(config, theme));
    }

    if (!template && config) {
      const themeInfo = await Http.get(`${config.client_url}/theme.json`);
      const text = await Http.getText(`${config.client_url}${themeInfo.loginPage}`);

      setTemplate(text);
      setTheme(themeInfo);
    }

    const html = _.template(template);
    setPreparedTemplate(html({
      'signWithPassword': signWithPassword,
      'canSignIn': canSignIn,
      'showEmail': showEmail,
      'errorMessage': errorMessage,
      'customErrorMessage': customErrorMessage,
      t: translations[currentLanguage],
      data: {
        email: (email),
        password: (password),
        registrationUrl: (config?.registration_url),
        portalUrl: (config?.portal_url),
        forgoutPasswordUrl: (config?.forgout_password_url)
      }
    }));

    if (currentLanguage) {
      const langDocument = document.getElementById(currentLanguage);
      langDocument?.classList.add('langActive');
    }

    const urlParams = new URLSearchParams(window.location.search);
    localStorage.setItem('returnUrl', urlParams.get('ReturnUrl'));
  });

  window.changeLanguage = (event, lang) => {
    setCurrentLanguage(lang);

    setTimeout(() => {
      const langDocument = document.getElementById(lang);
      langDocument.classList.add('langActive');

      localStorage.setItem('lang', lang);
    }, 0);
  };

  window.openTap = (event, formType = 'default') => {
    const defaultFormElement = document.getElementById("password-login-link");;
    const defaultFormElementContent = document.getElementById('login-pass');

    const edsFormElement = document.getElementById("eds-login-link");
    const edsFormElementContent = document.getElementById("eds");

    if (formType === 'default') {
      defaultFormElementContent.classList.add('active');
      edsFormElementContent.classList.remove('active');

      edsFormElement.classList.remove('active');
      defaultFormElement.classList.add('active');
    }

    if (formType === 'eds') {
      defaultFormElementContent.classList.remove('active');
      edsFormElementContent.classList.add('active');

      defaultFormElement.classList.remove('active');
      edsFormElement.classList.add('active');
    }
  };

  const sign = (userName, pass, xml, fullName, newEmail) => {
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get('ReturnUrl');

    Http.post(`${config.server_url}/api/AuthByEds/auth`, {
      userName: (userName),
      password: pass,
      returnUrl: url,
      email: (newEmail),
      signedXml: xml,
      fullName: (fullName),
    }).then(data => {
      if (data && data.isOk) {
        window.location = data.redirectUrl;
      } else if (data && !data.isOk) {
        if (!data.customError) {
          setErrorMessage(data.errorMessage);
        }
        setCustomErrorMessage(true);
      }
    });
  };

  window.selectCert = (event) => {
    event.preventDefault();

    ncaInstance.browseKeyStore(certStore, 'P12', "", (path) => {
      if (!path.result) return;

      ncaInstance.signXml3(certStore, 'AUTH', '<data>hello</data>', path.result, data => {
        const xml = data.result;
        setSignedXml(xml);
        Http.post(`${config.server_url}/api/Common/certInfo`, {
          Xml: xml
        }).then(data => {
          return Http.get(`${config.server_url}/api/Common/hasUser/${data.serialNumber}`).then(response => {
            if (response.hasUser) {
              sign(data.serialNumber, password, xml, data.fullName, "");
            } else {
              setShowEmail(true);
              setCanSignIn(true);
            }
          });
        });
      });
    });
  };

  window.signByLogin = (event) => {
    event.preventDefault();

    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get("ReturnUrl");

    Http.post(`${config.server_url}/api/AuthByLogin/auth`, {
      userName: login,
      password: pass,
      returnUrl: url
    }).then(data => {
      if (data && data.isOk) {
        window.location = data.redirectUrl;
      } else if (data && !data.isOk) {
        if (!data.customError) {
          setErrorMessage(data.errorMessage);
        }

        setCustomErrorMessage(true);
      }
    });
  };

  window.onChangeHandler = (event) => {
    const name = event.name;
    const value = event.value;

    name === 'userName' ? setLogin(value) : setPass(value);
  };

  window.visibilityPassword = (input, img) => {
    input.type = input.type === 'password' ? 'text' : 'password';
    img.src = input.type === 'password'
      ? '../images/visibility.svg'
      : '../images/visibility_off.svg';
  };

  return (
    <div>
      {(config && preparedTemplate) &&
        <span>
          <link rel="stylesheet" href={`${config.client_url}${theme.customCssFile}`}></link>
          <div dangerouslySetInnerHTML={{ __html: preparedTemplate }}></div>
        </span>
      }
    </div>
  );
}
