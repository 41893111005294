export class NcaLayer {
  webSocket;
  heartbeat_msg;
  heartbeat_interval;
  missed_heartbeats;
  missed_heartbeats_limit_min;
  missed_heartbeats_limit_max;
  missed_heartbeats_limit;
  callback;

  constructor() {
    this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
    this.heartbeat_msg = '--heartbeat--';
    this.heartbeat_interval = null;
    this.missed_heartbeats = 0;
    this.missed_heartbeats_limit_min = 3;
    this.missed_heartbeats_limit_max = 50;
    this.missed_heartbeats_limit = this.missed_heartbeats_limit_min;
    this.callback = null;

    this.init();
  }

  setMissedHeartbeatsLimitToMax() {
    this.missed_heartbeats_limit = this.missed_heartbeats_limit_max;
  }

  setMissedHeartbeatsLimitToMin() {
    this.missed_heartbeats_limit = this.missed_heartbeats_limit_min;
  }

  openDialog() {
    if (window.confirm("Ошибка при подключений к прослойке. Убедитесь что программа запущена и нажмите ОК") === true) {
      window.location.reload();
    }
  }

  init() {
    this.webSocket.onopen = function (event) {
      if (this.heartbeat_interval === null) {
        this.missed_heartbeats = 0;
        this.heartbeat_interval = setInterval(this.pingLayer, 2000);
      }
      console.log("Connection opened");
    };
  
    this.webSocket.onclose = function (event) {
      if (event.wasClean) {
        console.log('connection has been closed');
      } else {
        console.log('Connection error');
      }
      console.log('Code: ' + event.code + ' Reason: ' + event.reason);
    };
  
    this.webSocket.onmessage = (event) => {
      if (event.data === this.heartbeat_msg) {
        this.missed_heartbeats = 0;
        return;
      }
  
      var result = JSON.parse(event.data);
  
      if (result != null) {
        var rw = {
          code: result['code'],
          message: result['message'],
          result: result['result'],
          responseObject: result['responseObject'],
          getResult: function () {
            return this.result;
          },
          getMessage: function () {
            return this.message;
          },
          getResponseObject: function () {
            return this.responseObject;
          },
          getCode: function () {
            return this.code;
          },
          isSuccess: function () {
            return this.code == 200;
          }
        };
        if (this.callback) {
          this.callback(rw);
        }
      }
      console.log(event);
      this.setMissedHeartbeatsLimitToMin();
    };
  }

  pingLayer() {
    console.log("pinging...");
    try {
      this.missed_heartbeats++;
      if (this.missed_heartbeats >= this.missed_heartbeats_limit)
        throw new Error("Too many missed heartbeats.");
      this.webSocket.send(this.heartbeat_msg);
    } catch (e) {
      clearInterval(this.heartbeat_interval);
      this.heartbeat_interval = null;
      console.warn("Closing connection. Reason: " + e.message);
      this.webSocket.close();
    }
  }

  getActiveTokens(callBack) {
    var getActiveTokens = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "getActiveTokens"
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(getActiveTokens));
  }

  getKeyInfo(storageName, callBack) {
    var getKeyInfo = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "getKeyInfo",
      "args": [storageName]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(getKeyInfo));
  }

  signXml(storageName, keyType, xmlToSign, callBack) {
    console.log(this);
    var signXml = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "signXml",
      "args": [storageName, keyType, xmlToSign, "", ""]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    console.log('status', this.webSocket?.readyState, this.webSocket.CLOSED, this.webSocket.OPEN);
    if(this?.webSocket.readyState === this.webSocket.CLOSED || this?.webSocket.readyState === this.webSocket.CONNECTING) {
      if(window.confirm("Ошибка при подключении к NCALayer. Запустите NCALayer и нажмите ОК/NCALayer-ге қосылу кезінде қате пайда болды. NCALayer бағдарламасын іске қосыңыз және ОК түймесін басыңыз") === true) window.location.reload();
    }
    this.webSocket.send(JSON.stringify(signXml));
  }

  createCMSSignatureFromFile(storageName, keyType, filePath, flag, callBack) {
    var createCMSSignatureFromFile = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "createCMSSignatureFromFile",
      "args": [storageName, keyType, filePath, flag]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(createCMSSignatureFromFile));
  }

  showFileChooser(fileExtension, currentDirectory, callBack) {
    var showFileChooser = {
      "module": "kz.gov.pki.knca.commonUtils",
      "method": "showFileChooser",
      "args": [fileExtension, currentDirectory]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(showFileChooser));
  }


  ////////////////////////////////////////////////////////////////////////////////////////////
  browseKeyStore(storageName, fileExtension, currentDirectory, callBack) {
    var browseKeyStore = {
      "method": "browseKeyStore",
      "args": [storageName, fileExtension, currentDirectory]
    };
    this.callback = callBack;
    //TODO: CHECK CONNECTION
    this.setMissedHeartbeatsLimitToMax();
    if(this?.webSocket.readyState === this.webSocket.CLOSED || this?.webSocket.readyState === this.webSocket.CONNECTING) {
      if(window.confirm("Ошибка при подключении к NCALayer. Запустите NCALayer и нажмите ОК/NCALayer-ге қосылу кезінде қате пайда болды. NCALayer бағдарламасын іске қосыңыз және ОК түймесін басыңыз") === true) window.location.reload();
    }
    this.webSocket.send(JSON.stringify(browseKeyStore));
  }

  getKeys(storageAlias, storagePath, password, keyType, callBack) {
    var getKeys = {
      "method": "getKeys",
      "args": [storageAlias, storagePath, password, keyType]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(getKeys));
  }

  signXml2(storageName, storagePath, alias, password, xmlToSign, callBack) {
    var signXml = {
      "method": "signXml",
      "args": [storageName, storagePath, alias, password, xmlToSign]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(signXml));
  }

  signXml3(storageName, keyType, xmlToSign, container, callBack) {
    var signXml = {
      "method": "signXml",
      "args": [storageName, container, keyType, xmlToSign]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(signXml));
  }

  getNotAfter(storageName, storagePath, alias, password, callBack) {
    var getNotAfter = {
      "method": "getNotAfter",
      "args": [storageName, storagePath, alias, password]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(getNotAfter));
  }

  getNotBefore(storageName, storagePath, alias, password, callBack) {
    var getNotBefore = {
      "method": "getNotBefore",
      "args": [storageName, storagePath, alias, password]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(getNotBefore));
  }

  getSubjectDN(storageName, storagePath, alias, password, callBack) {
    var getSubjectDN = {
      "method": "getSubjectDN",
      "args": [storageName, storagePath, alias, password]
    };
    this.callback = callBack;
    this.setMissedHeartbeatsLimitToMax();
    this.webSocket.send(JSON.stringify(getSubjectDN));
  }
}